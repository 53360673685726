$page-width: 1200px;
$black: #111;
$dark-gray: #333;

body {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 1.125rem;
  font-color: $dark-gray;
  font-weight: 300;
  padding: 0;
  margin: 0 auto;
}

.main-image {
  width: 90vw;
  max-width: $page-width !important;
  margin: 0 auto !important;
}

@mixin visually-hidden {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* General helper classes */
.visually-hidden:not(:focus):not(:active):not(:focus-within) {
  @include visually-hidden;
}
.inline-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.inline-list li {
  display: inline;
}

.nav-layout {
  width: 80%;
  margin: 0 auto;
  border-top: 1px solid #444;
  padding: 1em;

  a {
    &:hover {
      text-decoration: underline;
      text-underline-offset: 7px;

      @media only screen and (min-height: 600px) and (max-width: 700px) {
        text-underline-offset: initial;
      }
    }
  }

  #menu-toggle {
    display: none;
  }
  #menu-toggle ~ label {
    display: none;
  }
  @media only screen and (min-height: 600px) and (max-width: 700px) {
    &.main-nav {
      border-top: none;
      width: auto;
      text-align: right;

      #menu-toggle ~ label {
        cursor: pointer;
        user-select: none;
        display: inline-block;
        font-size: 2em;
        transition: transform .1s;

        &:hover {
          transform: scale(1.2);
        }
      }
      .menu {
        top: 4em;
        display: block;
        position: absolute;
        right: 0;
        left: 0;
        padding: 0 2em;
        width: auto;
        background: white;
        text-align: center;
        border-top: 1px dotted rgba(0,0,0,0.4);
        border-bottom: 1px dotted rgba(0,0,0,0.4);
        box-shadow: -5px 5px 5px rgba(0,0,0,0.1);
      }

      #menu-toggle:checked ~ label {
        color: red;
      }
      #menu-toggle:not(:checked) ~ ul {
        @include visually-hidden;
      }
      li {
        margin: 0;
        a {
          display: block;
          padding: 0.8em;
        }
      }
    }
  }
}

/* Header */
.main-header {
  color: $black;

  h1 {
    margin: 0;

    img {
      margin: 0.5em;
      vertical-align: middle;
      max-height: 50px;
    }
  }

  nav {
    li {
      margin: 1em;

      a {
        color: $black;
        text-decoration: none;
      }
    }
  }

  @media only screen and (min-height: 600px) and (min-width: 700px) {
    margin: 0;
    text-align: center;
    margin: 0;
    width: 100%;
  }

  @media only screen and (min-height: 600px) and (max-width: 700px) {
    padding: 0 2em;
    h1 {
      float: left;
    }
    nav {
      li {
        margin: 0.5em;
      }
    }
  }
}

/* Footer */
.main-footer {
  background: white;
  color: $black;
  text-align: center;
  width: 100%;
  font-size: 0.7em;

  .content-wrapper {
    margin: 2em;
  }
  li {
    margin: 1em;

    @media only screen and (min-height: 600px) and (max-width: 700px) {
      display: block;
    }
  }
  a {
    color: $black;
    text-decoration: none;
  }
}

/* Main */
.main-content {
  width: 100%;

  .main-content__wrapper {
    width: $page-width;
    margin: 0 auto;
    max-width: 90vw;
    padding: 7em 0;
    @media only screen and (min-height: 600px) and (max-width: 700px) {
      padding: 2em 0;
    }
  }

  img {
    margin: 2em 0;
    max-width: 100%;
    max-height: 60vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    /* display: inline; */
    font-weight: 500;
    font-size: 1.4em;
    margin-bottom: 0.5em;
  }
  section {
    margin-bottom: 1.5em;
  }
}

/* Links lists */
.links-lists {
  display: block;
  width: 800px;
  margin: 2em auto;
  font-family: "helvetica";
  text-align: center;

  img {
    max-width: 600px;
  }
  h2 {
    /* display: inline; */
    font-weight: 500;
    font-size: 1.1em;
    margin-bottom: 0.5em;
  }
  section {
    margin-bottom: 1.5em;
  }
  ul li::after {
    content: ", ";
  }
  ul li:last-child::after {
    content: ".";
  }
}
.post-list {
  margin-top: 4em;
  @media only screen and (min-height: 600px) and (max-width: 700px) {
    margin-top: 2em;
  }

  li {
    display: block;
    margin-top: 5em;
    @media only screen and (min-height: 600px) and (max-width: 700px) {
      margin-top: 1em;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 0.7em;
    }
    a {
      text-decoration: none;
      color: $dark-gray;
      &:hover {
        h2 {
          text-decoration: underline;
          text-underline-offset: 5px;
        }
      }
    }
    .post-date {
      display: block;
      color: #777;
      font-size: 0.8em;
    }
  }
  .post-illustration {
    max-width: 100px;
    float: left;
  }
}
.post-article {
  ul {
    overflow: hidden;
  }
  hgroup {
    margin-bottom: 2em;
    h1 {
      font-weight: 500;
      margin-bottom: 0.4em;
    }
    p {
      color: #777;
      font-size: 0.9em;
    }
  }
}
.links-page {
  text-align: center;

  img {
    max-width: 500px;
  }
}

@media only screen and (min-width: 900px) {
  .floatRight {
    float: right;
    margin: 2em !important;
  }
  .floatLeft {
    float: left;
    margin: 2em !important;
  }
}
